/* CourseworkCard.css */

.coursework-card {
  background-color: #ffffff; /* White background */
  border: 1px solid #dddddd; /* Slightly bluish border for a modern feel */
  border-radius: 12px; /* Slightly more rounded corners */
  padding: 20px; /* Increase padding for a more spacious look */
  margin-bottom: 16px; /* Consistent margin between cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}

.coursework-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Slightly more lifted effect on hover */
  transform: translateY(-4px); /* Lifted effect on hover */
}

.coursework-title {
  font-size: 20px; /* Larger, bolder text for title */
  font-weight: bold;
  color: #333; /* Darker grey color for main text */
  margin-bottom: 10px; /* Spacing between title and other elements */
}

.student-count {
  font-size: 15px; /* Medium text for student count */
  color: #555; /* Slightly darker grey for consistency */
  margin-bottom: 12px; /* Space between count and toggle */
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Consistent spacing between toggle and text */
}

.checkbox-container {
  cursor: pointer; /* Cursor pointer for toggle interaction */
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Slightly larger width for better usability */
  height: 22px; /* Slightly larger height for better usability */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Default grey background */
  transition: .4s;
  border-radius: 34px; /* Rounded switch background */
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%; /* Rounded knob */
}

input:checked + .slider {
  background-color: #4a90e2; /* Blue color when checked, consistent with app color */
}

input:not(:checked) + .slider {
  background-color: #ccc; /* Default grey when not checked */
}

input:checked + .slider:before {
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px; /* Rounded border */
}

.slider.round:before {
  border-radius: 50%; /* Rounded knob */
}

.status-text {
  font-size: 14px;
  font-weight: 500; /* Medium font weight for emphasis */
  color: #4a90e2; /* Blue color for active state */
  margin-right: 10px; /* Space between text and toggle */
}

.course-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 180px;
}

.course-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.course-card-image {
  width: 100%;
  height: 160px;
  background-color: #e0e7ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-card-section {
  padding: 12px;
  font-size: 16px;
  color: #333;
  text-align: center;
  background-color: #ffffff;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .course-tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .course-card {
    width: 100%; /* Full-width within grid */
    border-radius: 8px;
  }

  .course-card-image {
    height: 160px;
  }

  .course-card-section {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .course-tiles {
    grid-template-columns: repeat(2, 1fr);
  }

  .course-card-image {
    height: 120px;
  }

  .course-card-section {
    padding: 6px;
    font-size: 12px;
  }
}

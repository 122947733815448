/* LoginScreen.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f3f3f3;
    padding: 1rem;
}



.login-form {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-align: center;
}

.google-login-button {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.google-login-button > div {
    display: inline-block; /* Allow the Google button to size itself naturally */
}


.login-form h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
}

.login-form h2 {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1.5rem;
}

.google-login-section {
    margin-bottom: 2rem;
    text-align: center;
}

.google-login-section h3 {
    font-size: 1.2rem;
    color: #333;
}

.login-divider {
    margin: 1.5rem 0;
    font-size: 0.9rem;
    color: #aaa;
    position: relative;
}

.login-divider::before,
.login-divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 40%;
    height: 1px;
    background-color: #ddd;
}

.login-divider::before {
    left: 0;
}

.login-divider::after {
    right: 0;
}

.edupage-login-section {
    margin-top: 2rem;
}

.edupage-login-section h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 0.5rem;
}

.login-form label {
    display: block;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
    text-align: left;
}

.login-form input[type="email"],
.login-form input[type="password"],
.password-container input[type="text"] {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-container input {
    padding-right: 2.5rem;
}

.show-password-button {
    position: absolute;
    right: 0.5rem;
    top: 38%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
}

/* Sign-in button */
.sign-in-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #143b3d;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sign-in-button:hover {
    background-color: #2b4e50;
}

.additional-links {
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
}

.additional-links a {
    color: #555;
    text-decoration: none;
    display: block;
    margin-top: 0.5rem;
}

.login-image {
    display: none; /* Hide on smaller screens */
}

#container {
    width: 100%;
}

.email-container {
    position: relative;
    display: flex;
    align-items: center;
}

.email-container input {
    width: 100%;
    padding-right: 2.5rem; /* Adjust space to fit the icon */
    padding-left: 2.5rem; /* Extra space if you want the icon on the left */
    border-radius: 4px;
    font-size: 1rem;
}

.edupage-icon {
    position: absolute;
    right: 0.7rem;
    top: 38%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none; /* Makes sure the icon doesn't interfere with input */
}

/* 2FA input container */
.code-inputs {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

/* Individual 2FA code input box */
.code-input {
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 1.25rem;
    outline: none;
    transition: border-color 0.3s;
}

/* Focused state for the input box */
.code-input:focus {
    border-color: #143b3d;
    box-shadow: 0 0 4px rgba(20, 59, 61, 0.3);
}

/* Styled confirm 2FA button (same as login button) */
.confirm-2fa-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #143b3d;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 1rem;
}

.confirm-2fa-button:hover {
    background-color: #2b4e50;
}

/* Loading indicator styles */
.confirm-2fa-button:disabled, .sign-in-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.confirm-2fa-button:disabled::after, .sign-in-button:disabled::after {
    content: 'Zadejte ověřovací kód.';
    display: block;
    font-size: 0.9rem;
    color: #666;
}

.notification-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}

.notification-box button {
    background: none;
    border: none;
    color: #721c24;
    font-weight: bold;
    cursor: pointer;
}

/* Error Border */
.error-border input {
    border-color: #dc3545;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .login-form h1 {
        font-size: 1.6rem;
    }

    .login-form {
        padding: 1.5rem;
    }
}

.sct-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sct-course-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.sct-rows {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sct-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 6px;
  transition: background-color 0.3s ease;
}

.sct-card:hover {
  background-color: #e6f0ff;
}

.sct-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.sct-name a {
  color: inherit;
  text-decoration: none;
}

.sct-name a:hover {
  text-decoration: underline;
}

.sct-deadline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.sct-optional {
  font-size: 14px;
  color: #666;
}

.sct-status-grade {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.sct-status-text {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: inherit;
  background-color: inherit;
}

.sct-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.sct-grade {
  font-size: 14px;
  color: #333;
}

@media (max-width: 768px) {
  .sct-card {
    padding: 8px;
  }

  .sct-name {
    font-size: 14px;
  }

  .sct-deadline,
  .sct-grade,
  .sct-status-text {
    font-size: 12px;
  }
}

.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
  }
  
.header {
  font-size: 24px;
  margin-bottom: 20px;
}
  
  .selection-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .label {
    margin-right: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  
  .select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .course-list {
    list-style-type: none;
    padding: 0;
  }
  
  .course-item {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .course-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .course-name {
    font-weight: bold;
  }
  
  .status-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  
  .status-dot.valid {
    background-color: green;
  }
  
  .status-dot.invalid {
    background-color: red;
  }
  
  .status-dot.exclude {
    background-color: grey;
  }
  
  .expand-button {
    padding: 3px 5px;
    margin-left: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .expand-button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  
  .reason-list {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .reason-item {
    margin-bottom: 5px;
  }
  
  .student-cards-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
  }
  
  .student-card-class {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .student-card-class:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .student-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: left;
  }
  
  .status-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: auto; /* Align the indicator to the right */
  }
  
  .status-indicator.ok {
    background-color: #28a745; /* Green for "ok" */
  }
  
  .status-indicator.not-ok {
    background-color: #dc3545; /* Red for "not ok" */
  }
  
  .class-name-back-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .class-name-back {
    cursor: pointer;
    color: #007bff;
    font-size: 20px;
    text-decoration: none;
  }
  
  .class-name-back:hover {
    color: #0056b3;
  }
  
  .student-name-selected {
    margin-left: 10px;
    font-weight: bold;
  }
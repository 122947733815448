html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
  overflow: auto;
  flex-direction: column; /* Stack items vertically on small screens */
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 80px;
}

.content-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 20px;
  transition: margin-left 0.3s ease;
  margin-left: 250px;
}

.sidebar.collapsed + .content-container {
  margin-left: 80px;
}

/* Footer styling */
.footer {
  background-color: #f8f9fa;
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid #ddd;
  text-align: center;
}

/* Info button styling */
.info-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #343a40;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  user-select: none;
}

.info-button:hover {
  background-color: #4a55f2;
}

.info-button .tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: #4a55f2;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.info-button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Responsive styling for mobile devices */
@media (max-width: 768px) {
  /* Make sidebar full-screen width when expanded */
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    z-index: 1050;
    display: none; /* Hidden by default */
  }

  /* Display sidebar when mobile menu is open */
  .sidebar.open {
    display: flex;
    flex-direction: column;
  }

  /* Center alignments for mobile header */
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9ecef;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1020;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
  }

  /* Mobile menu content styling */
  .mobile-menu-overlay {
    display: block; /* Enable overlay when sidebar is open */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }

  /* Adjust app wrapper for mobile */
  .app-wrapper {
    height: auto;
    padding-top: 60px; /* Adjust for fixed mobile header height */
  }

  /* Content container full-width on mobile */
  .content-container {
    padding: 10px;
    margin-left: 0;
    width: 100%;
  }

  .sidebar.collapsed + .content-container {
    margin-left: 0px;
}

  /* Footer full width on mobile */
  .footer {
    width: 100%;
    padding: 15px;
    position: relative;
    border-top: 1px solid #ddd;
  }

  /* Adjustments for the info button on mobile */
  .info-button {
    display: none;
  }
}

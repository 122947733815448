.student-course-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .student-course-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .student-course-card-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .student-course-card-teacher {
    font-size: 12px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .student-course-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .student-course-card-teacher-container {
    flex: 1;
    min-width: 0;
  }
  
  .student-course-card-percentage-status-container {
    display: flex;
    align-items: center;
    gap: 12px;
    overflow: hidden;
  }
  
  .student-course-card-percentage-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .student-course-card-icon {
    width: 16px;
    height: 16px;
  }
  
  .student-course-card-percentage {
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
  }
  
  .student-course-card-status {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .student-course-card-status.good {
    background-color: #ffa407;
  }
  
  .student-course-card-status.off {
    background-color: #6d26f0;
  }
  
  .student-course-card-status.excellent {
    background-color: #28a745;
  }
  
  .student-course-card-status.bad {
    background-color: #dc3545;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .student-course-card {
      flex-direction: column;
      padding: 8px;
      gap: 8px;
    }
  
    .student-course-card-title {
      font-size: 14px;
      text-overflow: ellipsis;
    }
  
    .student-course-card-teacher {
      font-size: 12px;
    }
  
    .student-course-card-content {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      overflow: hidden;
    }
  
    .student-course-card-percentage-status-container {
      font-size: 12px;
      gap: 12px;
    }
  
    .student-course-card-status {
      width: 10px;
      height: 10px;
    }
  }
  
.student-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header-container-student {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-container-student h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.controls-bar {
  display: inline-flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 10px;
  padding: 4px;
  position: relative;
  background-color: #e0e7ec;
  border-radius: 12px;
}

.bulk-action-button {
  padding: 8px;
  font-size: 16px;
  border: 0px;
  border-radius: 10px;
  background-color: #e0e7ec;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.bulk-action-button:hover {
  background-color: #f2f2f2;
}

.bulk-action-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

.button-icon {
  font-size: 16px;
  margin: 0; /* Center icon with no margin */
}

/* Picker Panels */
.deadline-picker-panel,
.status-picker-panel {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  min-width: 300px;
}

.deadline-picker-wrapper,
.status-picker-wrapper {
  position: relative;
  display: inline-block;
}

/* Action buttons and other content within picker panels */
.deadline-actions,
.status-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apply-deadline-button,
.apply-status-button {
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.apply-deadline-button:hover,
.apply-status-button:hover {
  background-color: #218838;
}

.apply-deadline-button:disabled,
.apply-status-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
  border: 1px solid #ccc;
}

/* Dropdown for Status Picker */
.status-dropdown {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-top: 10px;
  appearance: none;
  transition: border-color 0.2s ease;
}

.status-dropdown:focus {
  border-color: #007bff;
  outline: none;
}

/* Table and row styling */
.header-row, .student-card {
  display: grid;
  grid-template-columns: 40px 2fr 1fr 2fr 1fr auto;
  gap: 8px;
  align-items: center;
  padding: 10px;
}

.header-row {
  background-color: #f1f1f1;
  font-weight: bold;
}

.student-card {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
}

.student-card:hover {
  background-color: #f5f4f4;
}

.student-card.selected {
  background-color: #eeeeee;
}

.checkbox-header, .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-text {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.status-text .status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

/* Notification Box */
.notification-box {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 300px;
  z-index: 1000;
  font-size: 14px;
}

.notification-box p {
  margin: 0;
}

.notification-box.success {
  background-color: #e3fcec;
  color: #28a745;
  border: 1px solid #28a745;
}

.notification-box.error {
  background-color: #f8d7da;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.notification-box .close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: inherit;
  padding: 0;
}

@media (max-width: 768px) {

  .student-card {
    display: flex;
    flex-wrap: wrap;
  }

  .student-name {
    width: 90%;
  }
  .student-link {
    display: none; 
  }

  .header-row {
    display: none;
  }

  .student-grade {
    margin-left: auto;
    margin-right: 5px;
  }

  .student-status {
    margin-left: 10px;
  }
}

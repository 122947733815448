.container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .selection-container {
    margin-bottom: 20px;
  }
  
  .label {
    margin-right: 10px;
  }
  
  .select {
    margin-right: 10px;
    padding: 5px;
  }
  
  .button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .course-list {
    list-style-type: none;
    padding: 0;
  }
  
  .course-item {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .course-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .course-name {
    font-weight: bold;
  }
  
  .status-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
  
  .status-dot.valid {
    background-color: green;
  }
  
  .status-dot.invalid {
    background-color: red;
  }
  
  .status-dot.exclude {
    background-color: grey;
  }
  
  .expand-button {
    padding: 3px 5px;
    margin-left: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  .expand-button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  
  .reason-list {
    margin-top: 10px;
    padding-left: 20px;
  }
  
  .reason-item {
    margin-bottom: 5px;
  }
  
.teacher-view-container {
  padding: 20px;
}

.course-group {
  margin-bottom: 30px;
}

.course-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.course-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 24px;
  padding: 10px;
}

.topic-group {
  margin-bottom: 30px;
}

.topic-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.coursework-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.coursework-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.header-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
}

.view-toggle-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}

.view-toggle {
  display: flex;
  align-items: center;
  position: relative;
  width: 80px;
  height: 40px;
  background-color: #e0e7ec;
  border-radius: 12px;
  cursor: pointer;
  padding: 4px;
}

.toggle-icon {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 1px;
  align-items: center;
  font-size: 16px;
  color: #888;
  transition: color 0.3s ease;
}

.toggle-icon.active {
  color: #ffffff;
  z-index: 2;
}

.toggle-switch {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 32px;
  background-color: #4a90e2;
  border-radius: 10px;
  transition: left 0.3s ease, background-color 0.3s ease;
}

.toggle-switch.coursework {
  left: 6%;
}

.toggle-switch.student {
  left: 52%;
}

.grid-icon,
.list-icon {
  font-size: 16px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .teacher-view-container {
    padding: 0px;
  }

  .breadcrumb-container {
    font-size: 0.9rem;
  }

  .breadcrumb-arrow {
    width: 14px;
    height: 14px;
  }

  .course-wrapper-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .course-group {
    margin-bottom: 20px;
  }

  .course-title {
    font-size: 22px;
  }

  .course-tiles {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .coursework-container,
  .coursework-cards {
    grid-template-columns: 1fr;
  }

  .coursework-card {
    width: 100%;
  }

  .coursework-cards {
    gap: 0px;
  }

  .back-button {
    font-size: 1.2rem;
  }

  .back-button .back-arrow {
    width: 20px;
    height: 20px;
  }

  .back-button-text {
    font-size: 1.2rem;
  }

  .toggle-switch {
    width: 30px;
    height: 28px;
  }
}

@media (max-width: 480px) {
  .breadcrumb-container {
    font-size: 0.85rem;
  }

  .teacher-view-container {
    padding: 0px;
  }

  .course-title {
    font-size: 20px;
  }

  .course-group {
    margin-bottom: 15px;
  }

  .coursework-container,
  .coursework-cards {
    grid-template-columns: 1fr;
  }

  .coursework-card {
    padding: 14px;
    font-size: 14px;
  }

  .coursework-title {
    font-size: 18px;
  }

  .student-count {
    font-size: 14px;
  }

  .view-toggle-container {
    padding-right: 10px;
  }

  .toggle-switch {
    width: 28px;
    height: 26px;
  }

  .back-button {
    font-size: 1rem;
  }

  .back-button .back-arrow {
    width: 18px;
    height: 18px;
  }

  .back-button-text {
    font-size: 1rem;
  }
}

.export-button {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-left: 8px; /* Adjust to align with spacing around toggle */
  background-color: #e0e7ec; /* Match toggle switch background color */
  color: #000000; /* Icon color */
  border: none;
  border-radius: 12px; /* Rounded corners similar to toggle switch */
  cursor: pointer;
  font-size: 16px; /* Adjust icon size as needed */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.export-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.export-button:hover {
  background-color: #c4c7c9; /* Slightly darker on hover for contrast */
  transform: translateY(-2px); /* Lift effect on hover */
}

.export-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6); /* Focus outline similar to toggle focus */
}

.export-button:active {
  background-color: #2c5f9e; /* Darker shade when clicked */
  transform: translateY(0); /* Remove lift effect when clicked */
}

.export-button .fa-file-export {
  font-size: 20px; /* Adjust icon size */
}

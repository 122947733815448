.parent-view {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.student-dropdown {
  position: fixed;
  top: 20px;
  right: 0px;
  padding-right: 45px;
  z-index: 1021;
}

.student-dropdown select {
  padding: 8px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

.back-button {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .student-dropdown select {
    font-size: 0.9rem;
  }

  .student-dropdown {
    position: fixed;
    top: 15px;
    right: 28px;
    padding-right: 20px;
    z-index: 1021;
  }
}


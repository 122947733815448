.faq-container {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.faq-container h1 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: #333;
}

.faq-item {
    margin-bottom: 20px;
}

.faq-question {
    font-size: 1.2rem;
    color: #143B3D;
    margin-bottom: 10px;
    font-weight: bold;
}

.faq-answer {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
}

.faq-answer a {
    color: #007bff;
    text-decoration: none;
}

.faq-answer a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .faq-container {
        padding: 15px;
    }

    .faq-container h1 {
        font-size: 1.8rem;
    }

    .faq-question {
        font-size: 1.1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }
}

/* Sidebar styling */
.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    height: 98vh;
    width: 250px;
    background-color: #e9ecef;
    color: #343a40;
    transition: width 0.3s, border-radius 0.3s;
    border-radius: 15px;
    margin: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow: visible;
    z-index: 1010;
}

.sidebar.collapsed {
    width: 80px;
}

/* Sidebar header */
.sidebar-header {
    display: flex;
    align-items: center;
    padding: 1rem;
    position: relative;
}

.sidebar-logo {
    width: 40px;
    height: 40px;
}

.institution-name {
    margin-left: 10px;
    font-size: 1rem;
    transition: opacity 0.3s;
}

.sidebar.collapsed .institution-name {
    opacity: 0;
    width: 0;
    overflow: hidden;
}

/* Collapse button */
.collapse-button {
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ffffff;
    border: 2px solid #e9ecef;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, border-color 0.3s;
    z-index: 1;
}

.collapse-button:hover {
    background-color: #f0f0f0;
}

/* Sidebar menu */
.sidebar-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sidebar-item,
.footer-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.8rem 1rem;
    color: inherit;
    text-decoration: none;
    transition: background 0.3s, padding 0.3s;
    border-radius: 10px;
    margin: 0.4rem 0.9rem;
    cursor: pointer;
    background: none;
    border: none;
    user-select: none;
}

.sidebar.collapsed .sidebar-item,
.sidebar.collapsed .footer-button {
    justify-content: center;
    padding: 0.8rem;
}

.sidebar-item.active,
.footer-button:hover {
    background-color: #d1d8e0;
    color: #4a55f2;
}

.sidebar-item:hover {
    background-color: #d1d8e0;
}

.sidebar-item span,
.footer-button span {
    margin-left: 1rem;
    font-size: 0.9rem;
    transition: opacity 0.3s, width 0.3s;
}

.sidebar.collapsed .sidebar-item span,
.sidebar.collapsed .footer-button span {
    opacity: 0;
    width: 0;
    overflow: hidden;
}

/* Sidebar footer */
.sidebar-footer {
    padding: 1rem;
    border-top: 1px solid #e2e6ea;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 0 15px 15px;
}

/* Mobile Header styling */
.mobile-header {
    display: none;
}

/* Responsive styling */
@media (max-width: 768px) {
    /* Hide the sidebar on smaller screens */
    .sidebar {
        display: none;
    }

    /* Show the mobile header */
    .mobile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #e9ecef;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 1020;
        position: fixed;
        top: 0;
        width: 100%;
        height: 60px;
    }

    .mobile-header .header-logo {
        width: 40px;
        height: 40px;
    }

    .burger-button {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: #343a40;
        cursor: pointer;
    }

    /* Mobile menu overlay to cover entire screen */
    .mobile-menu-overlay {
        display: none; /* Hidden by default */
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1050;
    }

    .mobile-menu-overlay.active {
        display: block; /* Show overlay when active */
    }

    /* Mobile menu full-screen styling */
    .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #e9ecef;
        color: #343a40;
        height: 100vh;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1060;
        border-radius: 10px;
    }

    /* Close button in mobile menu */
    .mobile-menu .close-button {
        align-self: flex-end;
        font-size: 1.5rem;
        cursor: pointer;
        background: none;
        border: none;
        color: #343a40;
    }

    .collapse-button {
        display: none;
    }

    /* Sidebar item styling in mobile menu */
    .sidebar-item {
        font-size: 1.2rem;
        padding: 1rem;
        width: 100%;
        text-align: left;
    }

    .sidebar-footer {
        width: 100%;
    }

    .sidebar-menu {
        width: 100%;
    }

    .sidebar-item.active {
        background-color: #e9ecef;
    }

    .sidebar-item:hover {
        background-color: #e9ecef;
    }

    .sidebar-item span,
    .footer-button span {
        margin-left: 0.5rem;
    }
}

.course-cards-container {
  display: grid;
  grid-template-columns: 1fr; /* Single column layout for all screens */
  gap: 4px;
}

.coursework-details {
  margin-top: 16px;
}

.breadcrumb-container {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.breadcrumb-item {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
}

.breadcrumb-item.active {
  font-weight: bold;
  color: #000;
  cursor: default;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #666;
}

.breadcrumb-arrow {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  fill: #666;
  transform: rotate(180deg);
}
.integrated-course-group {
  border: 2px solid #ccc; /* Add a border to visually differentiate the group */
  border-radius: 12px; /* Rounded corners */
  padding: 12px; /* Padding inside the group */
  margin-bottom: 16px; /* Space between groups */
  background-color: #f9f9f9; /* Optional background color */
}

.integrated-course-header {
  display: flex; /* Use flexbox for layout */
  margin-bottom: 5px;;
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Ensure text and percentages are spaced correctly */
  gap: 16px; /* Space between elements */
  flex-wrap: nowrap; /* Prevent wrapping of child elements */
}

.integration-title {
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1; /* Allow the title to take as much space as possible */
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.integration-summary {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between icon and percentage */
}

.integration-summary-container {
  display: flex;
  align-items: center;
  gap: 12px; /* Space between percentage and status dot */
}

.integration-summary .student-course-card-icon {
  width: 16px;
  height: 16px;
}

.integration-summary .student-course-card-percentage {
  font-size: 14px;
  color: #000;
  margin: 0;
}

.integration-summary .student-course-card-status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* Adjustments for specific status colors */
.integration-summary .student-course-card-status.good {
  background-color: #ffa407;
}

.integration-summary .student-course-card-status.off {
  background-color: #6d26f0;
}

.integration-summary .student-course-card-status.excellent {
  background-color: #28a745;
}

.integration-summary .student-course-card-status.bad {
  background-color: #dc3545;
}

.student-course-card-content-integration {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}


@media (max-width: 768px) {
  .course-cards-container {
    grid-template-columns: 1fr; /* Ensure it remains a single column on smaller screens */
    margin: 0 auto; /* Center the content horizontally */
  }

  .breadcrumb-container {
    margin-bottom: 10px;
  }
}
